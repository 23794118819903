<template>
  <div>
    <van-popup v-model="show" round :style="{width}" @close="close">
      <div class="remarks_box">
        <div class="remarks_head">
          <p>备注</p>
          <span @click="close">
            <van-icon name="cross" />
          </span>
        </div>
        <div class="remarks_content" v-if="list.length > 0">
          <div class="scroll">
            <div class="item" v-for="item in list" :key="item.tea_id">
              <div class="item_title">
                <van-icon name="manager-o" />
                {{item.tea_name}}
              </div>
              <!-- <div class="item_content">备注备注备注备注备注备注备注备注备注备注备注备注备注备注</div> -->
              <div class="item_content">{{item.content}}</div>
            </div>
          </div>
        </div>
        <div v-else>
          <van-empty description="暂无数据" />
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
export default {
  props: ['width'],
  data() {
    return {
      show: false,
      dataForm: {},
      list: []
    };
  },
  methods: {
    async getList() {
      let path = ''
      let params = {}
      if (this.dataForm.is_divide == 1) {
        path = 'bk-sunreview/rev-notes'
        params = {
          review_id: this.dataForm.review_id,
          sunhost_id: this.dataForm.sunhost_id,
          sunadopt_id: this.dataForm.sunadopt_id,
          make_project: this.dataForm.make_project,
          uid: this.dataForm.uid,
          item_id: this.dataForm.item_id,
          plan_id: this.dataForm.plan_id,
          plandetail_id: this.dataForm.plandetail_id,
        }
      } else {
        path = 'pad/pad/notes'
        params = {
          uid: this.dataForm.uid,
          is_divide: this.dataForm.is_divide,
          make_project: this.dataForm.make_project,
          item_id: this.dataForm.item_id,
          plan_id: this.dataForm.plan_id,
          plandetail_id: this.dataForm.plandetail_id,
        }
      }
      const res = await this.$http.get(path, { params })
      if (res.code * 1 !== 1) {
        return this.$vantnotify(res.msg);
      }
      this.list = res.data instanceof Array ? res.data : []
      this.show = true
    },
    close() {
      this.$emit('remarksClose')
    }
  },
}
</script>

<style lang="less" scoped>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.remarks_head {
  height: 0.42rem;
  line-height: 0.42rem;
  padding: 0 0.14rem;
  background: url("~@/assets/img/form_head.png") top left/100% 100% no-repeat;
  p {
    font-size: 0.16rem;
    color: #750100;
    text-align: center;
  }
  span {
    position: absolute;
    top: 0;
    right: 0.14rem;
    font-size: 0.16rem;
    color: #750100;
  }
}
.remarks_content {
  padding: 0.16rem 0.16rem 0.4rem;
  height: 60vh;
  // display: flex;
  .scroll {
    overflow-y: scroll;
    height: 100%;
  }
  .item {
    border: 0.01rem solid #efefef;
    border-radius: 0.06rem;
    overflow: hidden;
    margin-bottom: 0.16rem;
    .item_title {
      line-height: 0.4rem;
      background: #efefef;
      padding: 0 0.12rem;
      font-size: 0.16rem;
      color: #602600;
      .van-icon {
        margin-right: 0.09rem;
      }
    }
    .item_content {
      padding: 0.1rem 0.13rem;
      font-size: 0.14rem;
      color: #666666;
      word-break: break-word;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
}
</style>