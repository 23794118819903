<template>
  <div class="player">
    <div class="prism-player" :id="'player-con'+id"></div>
  </div>
</template>

<script>
import { getPlayAuth } from "@/api/index";
export default {
  props: {
    vId: {
      type: String,
    //   required: true
    },
    id: {
      type: Number
    },
    width: {
      type: String
    },
    height: {
      type: String
    },
    format: {
      type: String
    },
    barvis: {
      type: String,
      default: 'click'
    },
    autoplay: {
      type: Boolean
    }
  },
  data () {
    return {
      videoId: '',
      playAuth: ''
    //   coverURL: ''
    }
  },
  mounted () {
  	// 根据父组件传入的videoId调用接口获取播放凭证和视频封面
    if (this.vId) {
        let data;
        this.videoId = this.vId;
        data = {
            video_id: this.videoId
        }
        getPlayAuth(data).then(res => {
            this.playAuth = res.data.voucher
        }).then(() => {
            this.getVideo()
        }).catch(err => {
            // this.$opener.close()
        });
    }
    // this.getVideo()
  },
  methods: {
    getVideo() {
      var player = new Aliplayer({
        "id": "player-con" + this.id,
        "vid": this.videoId,
        "playauth": this.playAuth,
        "width": this.width || "100%",
        "height": this.height || "500px",
        "autoplay": this.autoplay,
        "format": this.format,
        "controlBarVisibility": this.barvis
      }, function (player) {
          // console.log("The player is created");
        }
      );
    },
  }
}
</script>
<style lang='less' scoped>
.player {
  p {
    color: #fff;
  }
}
</style>