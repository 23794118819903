<template>
  <div ref="more">
    <van-popup
      v-model="show"
      round
      position="bottom"
      :style="{ width }"
      @close="close"
    >
      <div class="more_box">
        <div class="more_head">
          <p>{{ dataForm.name }}</p>
          <span @click="close"><van-icon name="cross" /></span>
        </div>
        <div class="more_content">
          <el-table
            :data="list"
            :max-height="500"
            style="width: 100%"
            stripe
            border
          >
            <!-- max-height="250" -->
            <el-table-column
              prop="ctime"
              label="训练时间"
              min-width="17%"
              align="center"
            >
              <template slot-scope="scope">
                <p v-if="$route.query.subject == 1">{{ $stampToTime(scope.row.ctime, "YYYY-MM-DD HH:mm") }}</p>
                <p v-if="$route.query.subject == 2">{{ $stampToTime(scope.row.train_time, "YYYY-MM-DD HH:mm") }}</p>
              </template>
            </el-table-column>
            <el-table-column
              prop="tea_name"
              label="训练老师"
              min-width="13%"
              align="center"
            ></el-table-column>
            <el-table-column
              prop="big"
              label="大/小试探"
              min-width="14%"
              align="center"
              v-if="
                $route.query.subject == 1 &&
                (dataForm.teach_type == 1 || dataForm.teach_type == 2)
              "
            >
              <template slot-scope="scope">
                <p>
                  {{
                    scope.row.big.length > 0
                      ? scope.row.big.map((e) => ["N", "Y"][e.key]).join("")
                      : scope.row.small.map((e) => ["N", "Y"][e.key]).join("")
                  }}
                </p>
              </template>
            </el-table-column>
            <el-table-column
              prop="train"
              label="训练记录"
              min-width="28%"
              align="center"
              v-if="
                $route.query.subject == 1 &&
                (dataForm.teach_type == 1 || dataForm.teach_type == 2)
              "
            >
              <template slot-scope="scope">
                <div class="train_list">
                  <div
                    class="d_item"
                    v-for="(item1, index1) in scope.row.train"
                    :key="index1"
                    :class="{ err: item1.key == 2 }"
                  >
                    <p v-if="item1.key != 2">{{ item1.txt }}</p>
                    <van-popover
                      v-else
                      v-model="item1.showPopover"
                      trigger="click"
                      :closeOnClickAction="false"
                      :getContainer="
                        () => {
                          $refs.more;
                        }
                      "
                    >
                      <div class="pop">
                        <div
                          class="pop_item"
                          v-for="(item2, index2) in item1.list"
                          :key="index2"
                        >
                          {{ item2.txt }}
                        </div>
                      </div>
                      <template #reference>
                        <p>{{ item1.txt }}</p>
                      </template>
                    </van-popover>
                  </div>
                </div>
              </template>
            </el-table-column>
            <el-table-column
              prop="score"
              label="分数"
              min-width="8%"
              align="center"
              v-if="
                $route.query.subject == 1 &&
                (dataForm.teach_type == 1 || dataForm.teach_type == 2)
              "
            ></el-table-column>
            <el-table-column
              prop="modality"
              label="辅助形式"
              min-width="13%"
              align="center"
              v-if="
                $route.query.subject == 1 &&
                (dataForm.teach_type == 1 || dataForm.teach_type == 2)
              "
            >
              <template slot-scope="scope">
                <p>
                  {{
                    [
                      "",
                      "位置辅助",
                      "视觉辅助",
                      "示范辅助",
                      "手势辅助",
                      "眼表辅助",
                      "身体辅助",
                    ][scope.row.modality]
                  }}
                </p>
              </template>
            </el-table-column>
            <el-table-column
              prop="target"
              label="目标"
              min-width="30%"
              align="center"
              v-if="
                $route.query.subject == 1 &&
                (dataForm.teach_type == 3 || dataForm.teach_type == 4)
              "
            ></el-table-column>
            <el-table-column
              prop="train"
              label="目标完成情况"
              min-width="12%"
              align="center"
              v-if="
                $route.query.subject == 1 &&
                (dataForm.teach_type == 3 || dataForm.teach_type == 4)
              "
            >
              <template slot-scope="scope">
                <p>
                  {{ ["", "独立完成", "半辅", "全辅"][scope.row.train[0].key] }}
                </p>
              </template>
            </el-table-column>
            <el-table-column
              prop="is_train"
              label="项目状态"
              min-width="8%"
              align="center"
              v-if="
                $route.query.subject == 1 &&
                (dataForm.teach_type == 3 || dataForm.teach_type == 4)
              "
            >
              <template slot-scope="scope">
                <p>
                  {{ ["未通过", "通过"][scope.row.is_train] }}
                </p>
              </template>
            </el-table-column>
            <el-table-column
              prop="train"
              label="训练记录"
              min-width="54%"
              align="center"
              v-if="$route.query.subject == 2"
            >
              <template slot-scope="scope">
                <div class="train_list many_train">
                    <div 
                        class="name" 
                        :class="{off:item.status}"
                        v-for="item in scope.row.train"
                        :key="item.uid"
                        >
                        {{ item.stu_name }}({{ ['N','Y'][item.is_adopt] }})
                    </div>
                    <div class="zw" v-for="i in 10" :key="i"></div>
                </div>
              </template>
            </el-table-column>
            <el-table-column
              prop="pass_rate"
              label="通过率"
              min-width="8%"
              align="center"
              v-if="$route.query.subject == 2"
            >
              <template slot-scope="scope">
                <p>{{scope.row.pass_rate}}%</p>
              </template>
            </el-table-column>
            <el-table-column
              prop="notes"
              label="备注"
              min-width="8%"
              align="center"
              v-if="$route.query.subject == 2"
            >
              <template slot-scope="scope">
                <el-button
                  @click.native.prevent="manylook('备注', scope.row.train)"
                  type="text"
                  size="small"
                >
                  查看
                </el-button>
              </template>
            </el-table-column>
            <el-table-column
              prop="edict"
              label="指令"
              min-width="8%"
              align="center"
              v-if="
                $route.query.subject == 1 &&
                (dataForm.teach_type == 1 || dataForm.teach_type == 2)
              "
            >
              <template slot-scope="scope">
                <el-button
                  @click.native.prevent="look('指令', scope.row.edict)"
                  type="text"
                  size="small"
                >
                  查看
                </el-button>
              </template>
            </el-table-column>
            <el-table-column
              prop="notes"
              label="备注"
              min-width="8%"
              align="center"
              v-if="$route.query.subject == 1"
            >
              <template slot-scope="scope">
                <el-button
                  @click.native.prevent="look('备注', scope.row.notes)"
                  type="text"
                  size="small"
                >
                  查看
                </el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </van-popup>

    <van-popup
      v-model="lookShow"
      round
      style="width: 6rem"
      @close="lookShow = false"
    >
      <div class="look_box">
        <div class="look_head">
          <p>{{ lookInfo.title }}</p>
          <span @click="lookShow = false"><van-icon name="cross" /></span>
        </div>
        <div class="look_content">{{ lookInfo.content }}</div>
      </div>
    </van-popup>

    <van-popup
      v-model="manyLookShow"
      round
      style="width: 6rem"
      @close="manyLookShow = false"
    >
      <div class="look_box manylook_box">
        <div class="look_head">
          <p>{{ manyLookInfo.title }}</p>
          <span @click="manyLookShow = false"><van-icon name="cross" /></span>
        </div>
        <div class="look_content">
            <div class="scroll">
                <div class="item" v-for="item in manyLookInfo.list" :key="item.uid">
                    <div class="item_title"><van-icon name="manager-o" />{{item.stu_name}}</div>
                    <div class="item_content">{{item.notes}}</div>
                </div>
            </div>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import "@/plugin/element";
export default {
  props: ["width"],
  data() {
    return {
      show: false,
      dataForm: {},
      list: [],
      lookShow: false,
      lookInfo: {},
      manyLookShow: false,
      manyLookInfo: {},
    };
  },
  methods: {
    init() {
      this.list = JSON.parse(JSON.stringify(this.dataForm.tableData));
      this.list.map((i) => {
        return i.train.map((o) => {
          if (o.key == 2) {
            this.$set(o, "showPopover", false);
          }
        });
      });
      this.show = true;
    },
    look(title, content) {
      this.lookShow = true;
      this.lookInfo.title = title;
      this.lookInfo.content = content;
    },
    manylook(title, list){
      this.manyLookShow = true;
      this.manyLookInfo.title = title;
      this.$set(this.manyLookInfo,'list',JSON.parse(JSON.stringify(list)))
    },
    close() {
      this.$emit("moreDataClose");
    },
  },
};
</script>

<style lang="less" scoped>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.more_head,
.look_head {
  height: 0.42rem;
  line-height: 0.42rem;
  padding: 0 0.14rem;
  background: url("~@/assets/img/form_head.png") top left/100% 100% no-repeat;
  p {
    font-size: 0.16rem;
    color: #750100;
    text-align: center;
  }
  span {
    position: absolute;
    top: 0;
    right: 0.14rem;
    font-size: 0.16rem;
    color: #750100;
  }
}
.more_content {
  padding: 0.16rem 0.16rem 0.4rem;
  max-height: 70vh;
  /deep/ table {
    width: 100% !important;
    &.el-table__header tr th {
      background: #f1f1f1;
      font-size: 0.14rem;
      color: #333;
    }
    td,
    th.el-table__cell {
      border-color: #e3e3e3;
    }
  }
  .el-table {
    border-radius: 0.1rem 0.1rem 0px 0px;
  }
  /deep/ .el-table__body-wrapper {
    .cell {
      font-size: 0.13rem;
      .el-button {
        width: 0.5rem;
        height: 0.28rem;
        border-radius: 0.14rem;
        background: #ffebeb;
        color: #f14947;
      }
    }
  }
  .train_list {
    display: flex;
    flex-wrap: wrap;
  }
  .d_item {
    width: 0.4rem;
    line-height: 0.24rem;
    text-align: center;
    background: #f3f3f3;
    border-radius: 0.05rem;
    border: 0.01rem solid #d9d9d9;
    margin: 0.03rem;
    &.err {
      background: #ffe8e8;
      border: 1px solid #ffbcbc;
      color: #ff5f4c;
      .pop {
        display: flex;
        padding: 0.12rem 0.06rem;
        .pop_item {
          width: 0.42rem;
          height: 0.24rem;
          background: #f3f3f3;
          border: 0.01rem solid #d9d9d9;
          margin: 0 0.06rem;
          border-radius: 0.12rem;
        }
      }
    }
  }
}
.look_content {
  padding: 0.14rem;
  font-size: 0.14rem;
  color: #666;
  line-height: 24px;
  word-break: break-word;
}
.many_train{
    justify-content: space-between;
}
.name{
    width: .98rem;
    line-height: .3rem;
    background: #F9F9F9;
    border-radius: .04rem;
    border: .01rem solid #E3E3E3;
    font-size: .14rem;
    text-align: center;
    margin: .03rem 0;
    color: #333;
    overflow: hidden;
    display: inline-block;
    &.off{
        color: #D1D1D1;
        position: relative;
        &::after{
            content: '假';
            display: block;
            width: .3rem;
            height: .2rem;
            background: #FF5F4C;
            border-radius: .15rem;
            position: absolute;
            top: -.03rem;
            right: -.13rem;
            color: #fff;
            font-size: .12rem;
            line-height: .23rem;
            text-align: left;
            padding-left: .03rem;
            box-sizing: border-box;
        }
    }
}
.zw{
    width: 1rem;
    height: 0;
}
.manylook_box{
    .look_content{
        height: 60vh;
    }
    .scroll{
        overflow-y: scroll;
        height: 100%;
    }
    .item{
        border: .01rem solid #EFEFEF;
        border-radius: .06rem;
        overflow: hidden;
        margin-bottom: .16rem;
        .item_title{
            line-height: .4rem;
            background: #EFEFEF;
            padding: 0 .12rem;
            font-size: .16rem;
            color: #602600;
            .van-icon{
                margin-right: .09rem;
            }
        }
        .item_content{
            padding: .1rem .13rem;
            font-size: .14rem;
            color: #666666;
        }
        &:last-child{
            margin-bottom: 0;
        }
    }
}
</style>