<template>
  <div>
    <van-popup v-model="show" round :style="{width}" @close="close">
      <div class="form_box">
        <div class="form_head">
          <span class="cancel" @click="close">取消</span>
          <p>备注</p>
          <span class="ok" @click="ok">保存</span>
        </div>
        <div class="form_content">
          <textarea maxlength="200" v-model="dataForm.content"></textarea>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
export default {
  props: ['width'],
  data() {
    return {
      show: false,
      dataForm: {}
    };
  },
  methods: {
    init() {
      this.show = true
    },
    async ok() {
      // let params = {
      //     id: this.dataForm.id,
      //     sundetail_id: this.dataForm.sundetail_id,
      //     uid: this.dataForm.uid,
      //     content: this.dataForm.content,
      // }
      // const res = await this.$http.post(this.dataForm.submitURL, params)
      // if (res.code*1 !== 1) {
      //     return this.$vantnotify(res.msg)
      // }
      this.$emit('formClose', this.dataForm)
    },
    close() {
      this.$emit('formClose')
    }
  },
}
</script>

<style lang="less" scoped>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.form_head {
  height: 0.42rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 0.14rem;
  background: url("~@/assets/img/form_head.png") top left/100% 100% no-repeat;
  span {
    font-size: 0.14rem;
    &.cancel {
      color: #666;
    }
    &.ok {
      color: #f14947;
    }
  }
  p {
    font-size: 0.16rem;
    color: #750100;
  }
}
.form_content {
  padding: 0.14rem;
  textarea {
    width: 100%;
    height: 2.2rem;
    font-size: 0.14rem;
    color: #666;
    border: 1px solid #bebebe;
    border-radius: 0.1rem;
    resize: none;
    padding: 0.1rem 0.14rem;
    line-height: 0.24rem;
  }
}
</style>